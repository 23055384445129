<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('MissedCallTemplateCreate')"
    @edit="(id) => onEdit('MissedCallTemplateEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'MissedCallTemplates',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'MissedCallTemplates',
      tableCaption: 'Шаблоны ответов для пропущенных звонков',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Шаблоны ответов для пропущенных звонков',
        },
      ],
      tableHeaders: [
        { text: 'Текст', alias: 'text', order: 'text' },
        // {
        //   text: 'Коллекция ключей',
        //   alias: 'collectionKey',
        //   order: 'collectionKey',
        // },
        {
          text: 'Группа шаблонов',
          alias: 'groupName',
          order: 'groupName',
        },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
